import { IActionTypeBase } from '../../../base/IBaseActionTypes';

export enum eCommonActionTypeIds {
	CHANGE_COSO_MODE = 'CHANGE_COSO_MODE',
	CHANGE_TRUONG_MODE = 'CHANGE_TRUONG_MODE',
	CHANGE_NAMHOC_MODE = 'CHANGE_NAMHOC_MODE',
	CHANGE_HOCKY_MODE = 'CHANGE_HOCKY_MODE',
	CHANGE_MENU_MODE = 'CHANGE_MENU_MODE',
	CHANGE_TUVANVIEN_MODE = 'CHANGE_TUVANVIEN_MODE',
	HEADER_RESET_SELECTED = 'HEADER_RESET_SELECTED',
}

export interface CommonAction_CHANGE_COSO_MODE extends IActionTypeBase<eCommonActionTypeIds.CHANGE_COSO_MODE, number> {}
export interface CommonAction_CHANGE_TRUONG_MODE extends IActionTypeBase<eCommonActionTypeIds.CHANGE_TRUONG_MODE, number> {}
export interface CommonAction_CHANGE_NAMHOC_MODE extends IActionTypeBase<eCommonActionTypeIds.CHANGE_NAMHOC_MODE, string> {}
export interface CommonAction_CHANGE_HOCKY_MODE extends IActionTypeBase<eCommonActionTypeIds.CHANGE_HOCKY_MODE, number> {}
export interface CommonAction_CHANGE_MENU_MODE extends IActionTypeBase<eCommonActionTypeIds.CHANGE_MENU_MODE, string> {}
export interface CommonAction_CHANGE_TUVANVIEN_MODE extends IActionTypeBase<eCommonActionTypeIds.CHANGE_TUVANVIEN_MODE, number> {}
export interface CommonAction_HEADER_RESET_SELECTED extends IActionTypeBase<eCommonActionTypeIds.HEADER_RESET_SELECTED, undefined> {}

export type CommonActionTypes =
	| CommonAction_CHANGE_MENU_MODE
	| CommonAction_HEADER_RESET_SELECTED
	| CommonAction_CHANGE_COSO_MODE
	| CommonAction_CHANGE_TRUONG_MODE
	| CommonAction_CHANGE_NAMHOC_MODE
	| CommonAction_CHANGE_HOCKY_MODE
	| CommonAction_CHANGE_TUVANVIEN_MODE;
