export const data1Info = [
	{ name: 'Lead', so_luong: 5000 },
	{ name: 'Tiềm năng', so_luong: 4000 },
	{ name: 'Thi đầu vào', so_luong: 3000 },
	{ name: 'Đặt cọc', so_luong: 2000 },
	{ name: 'Đóng phí', so_luong: 1000 },
];

export const data2Info = [
	{
		name: 'Facebook',
		so_luong: 3,
		color: '#576b8c',
	},
	{
		name: 'Google',
		so_luong: 5,
		color: '#b750ba',
	},
	{
		name: 'Website',
		so_luong: 10,
		color: '#ba4618',
	},
];

export const data3Source = [
	{
		value: '1',
		name: 'Campain 1',
		color: '#576b8c',
		data: [
			{ month: 1, so_luong: 3 },
			{ month: 2, so_luong: 4 },
			{ month: 3, so_luong: 10 },
			{ month: 4, so_luong: 3 },
		],
	},
	{
		value: '2',
		name: 'Campain 2',
		color: '#b750ba',
		data: [
			{ month: 1, so_luong: 4 },
			{ month: 2, so_luong: 6 },
			{ month: 3, so_luong: 9 },
			{ month: 4, so_luong: 4 },
		],
	},
	{
		value: '3',
		name: 'Campain 3',
		color: '#ba4618',
		data: [
			{ month: 1, so_luong: 5 },
			{ month: 2, so_luong: 2 },
			{ month: 3, so_luong: 1 },
			{ month: 4, so_luong: 8 },
		],
	},
];

export const data4Source = [
	{
		value: 'entrance',
		name: 'Thi đầu vào',
		color: '#576b8c',
		data: [
			{ staff: 'Nhân viên 1', so_luong: 3 },
			{ staff: 'Nhân viên 2', so_luong: 4 },
			{ staff: 'Nhân viên 3', so_luong: 10 },
			{ staff: 'Nhân viên 4', so_luong: 3 },
		],
	},
	{
		value: 'deposit',
		name: 'Đặt cọc',
		color: '#b750ba',
		data: [
			{ staff: 'Nhân viên 1', so_luong: 3 },
			{ staff: 'Nhân viên 2', so_luong: 4 },
			{ staff: 'Nhân viên 3', so_luong: 2 },
			{ staff: 'Nhân viên 4', so_luong: 3 },
		],
	},
	{
		value: 'closetheair',
		name: 'Đóng phi',
		color: '#ba4618',
		data: [
			{ staff: 'Nhân viên 1', so_luong: 3 },
			{ staff: 'Nhân viên 2', so_luong: 4 },
			{ staff: 'Nhân viên 3', so_luong: 6 },
			{ staff: 'Nhân viên 4', so_luong: 5 },
		],
	},
];

export const data5Source = [
	{
		name: 'Lead',
		so_luong: 3,
	},
	{
		name: 'Tiềm năng',
		so_luong: 2,
	},
	{
		name: 'Thi đầu vào',
		so_luong: 3,
	},
	{
		name: 'Đặt cọc',
		so_luong: 4,
	},
];
