import { ApiClient } from '../../base/_ApiClient';
import { ChangePasswordRequest, LoginRequest } from '../../models/my-system/accounts';

export const AccountApi = {
	Login: (data: LoginRequest) => {
		return ApiClient.LOGIN('/auth/login', data);
	},
	Detail: () => {
		return ApiClient.GET(`/auth/account-info`);
	},
	ChangPassword: (data: ChangePasswordRequest) => {
		return ApiClient.PUT('/auth/change-password', data);
	},
};
