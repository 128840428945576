import { useMemo } from 'react';
import {
	DxArgumentAxis,
	DxChart,
	DxCommonSeriesSettings,
	DxGrid,
	DxLegend,
	DxSeries,
	DxTooltip,
} from '../../../components/common/DxCharts';

interface BaoCaoCampainChartProps {
	data: any[];
}

const BaoCaoCampainChart = ({ data }: BaoCaoCampainChartProps) => {
	const listLeadCampainDate = useMemo(() => {
		const _list = data.reduce((prev, curr, index) => {
			const data = curr.data;
			data.forEach((val: any) => {
				if (!prev[val.month]) {
					prev[val.month.toString()] = { [(index + 1).toString()]: val.so_luong };
				} else {
					prev[val.month.toString()] = {
						...prev[val.month.toString()],
						[(index + 1).toString()]: val.so_luong,
					};
				}
			});
			return prev;
		}, {});
		return Object.keys(_list).map((val) => {
			return {
				month: 'Tháng ' + val.toString(),
				..._list[val],
			};
		});
	}, [data]);

	return (
		<DxChart id='chart' palette='Violet' dataSource={listLeadCampainDate}>
			{/* <Size width={800} height={350} /> */}
			<DxCommonSeriesSettings argumentField='month' type={'line'} />
			{data.map((item: any, index: number) => (
				<DxSeries
					// color={item.color}
					key={(index + 1).toString()}
					valueField={(index + 1).toString()}
					name={item.ten_chien_dich}
				/>
			))}
			<DxArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
				<DxGrid visible={true} />
			</DxArgumentAxis>
			<DxLegend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='right' />
			{/* <DxTitlePieChart text={'Lead by campain'}>
				<DxFontPieChart size={12} weight={600} />
			</DxTitlePieChart> */}

			<DxTooltip enabled />
		</DxChart>
	);
};

export default BaoCaoCampainChart;
