import { AccountInfo, ChangePasswordRequest, LoginRequest, LoginResponse } from '../../../models/my-system/accounts';
import { IActionTypeBase } from '../../../base/IBaseActionTypes';

export enum eAccountActionTypeIds {
	LOGIN_REQUEST = 'LOGIN_REQUEST',
	LOGIN_SUCCESS = 'LOGIN_SUCCESS',
	LOGIN_FAILURE = 'LOGIN_FAILURE',
	LOGOUT = 'LOGOUT',
	LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

	GET_ACCOUNT_START = 'GET_ACCOUNT_START',
	GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST',
	GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS',
	GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE',

	CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
	CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
	CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE',
}

export interface Login_Request_Action extends IActionTypeBase<eAccountActionTypeIds.LOGIN_REQUEST, LoginRequest> {}
export interface Login_Success_Action extends IActionTypeBase<eAccountActionTypeIds.LOGIN_SUCCESS, LoginResponse | undefined> {}
export interface Login_Failure_Action extends IActionTypeBase<eAccountActionTypeIds.LOGIN_FAILURE, Error | string> {}

export interface Logout_Request_Action extends IActionTypeBase<eAccountActionTypeIds.LOGOUT, undefined> {}
export interface Logout_Success_Action extends IActionTypeBase<eAccountActionTypeIds.LOGOUT_SUCCESS, undefined> {}

export interface GetAccount_Request_Action extends IActionTypeBase<eAccountActionTypeIds.GET_ACCOUNT_REQUEST, undefined> {}
export interface GetAccount_Success_Action extends IActionTypeBase<eAccountActionTypeIds.GET_ACCOUNT_SUCCESS, AccountInfo | null> {}
export interface GetAccount_Failure_Action extends IActionTypeBase<eAccountActionTypeIds.GET_ACCOUNT_FAILURE, Error | string> {}

export interface ChangePassword_Request_Action
	extends IActionTypeBase<eAccountActionTypeIds.CHANGE_PASSWORD_REQUEST, ChangePasswordRequest> {}
export interface ChangePassword_Success_Action extends IActionTypeBase<eAccountActionTypeIds.CHANGE_PASSWORD_SUCCESS, string> {}
export interface ChangePassword_Failure_Action extends IActionTypeBase<eAccountActionTypeIds.CHANGE_PASSWORD_FAILURE, Error | string> {}

export type AccountActionsTypes =
	| Login_Request_Action
	| Login_Success_Action
	| Login_Failure_Action
	| Logout_Request_Action
	| Logout_Success_Action
	| GetAccount_Request_Action
	| GetAccount_Success_Action
	| GetAccount_Failure_Action
	| ChangePassword_Request_Action
	| ChangePassword_Success_Action
	| ChangePassword_Failure_Action;
