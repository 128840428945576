import moment from 'moment';
import { useState } from 'react';
import BaoCaoNhanSuChart from './baocao-coso/BaoCaoNhanSuChart';
import BaoCaoNhanSuPieChart from './baocao-coso/BaoCaoNhanSuPieChart';
import BaoCaoTongBox from './baocao-coso/BaoCaoTongBox';
import BaoCaoHocSinhTheoHePieChart from './baocao-hocsinh/BaoCaoHocSinhTheoHePieChart';
import BaoCaoHocSinhTheoKhoi from './baocao-hocsinh/BaoCaoHocSinhTheoKhoi';
import BaoCaoLopTheoKhoiPieChart from './baocao-lophoc/BaoCaoLopTheoKhoiPieChart';
import BaoCaoLopTheoTruong from './baocao-lophoc/BaoCaoLopTheoTruong';
import BaoCaoHocLucChart from './baocao-renluyen/BaoCaoHocLucChart';
import BaoCaohanhKiemChart from './baocao-renluyen/BaoCaohanhKiemChart';

const Dashboard = () => {
	return (
		<>
			<BaoCaoTongBox />
			<div className='nk-block'>
				<div className='row g-gs'>
					<div className='col-md-7 col-xxl-7'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Khối - Hệ - Lớp</h6>
								</div>
								<BaoCaoLopTheoTruong />
							</div>
						</div>
					</div>
					<div className='col-md-5 col-xxl-5'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Thống kê lớp theo khối</h6>
								</div>
								<BaoCaoLopTheoKhoiPieChart />
							</div>
						</div>
					</div>
					<div className='col-md-4 col-xxl-4'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Báo cáo nhân sự</h6>
								</div>
								<BaoCaoNhanSuPieChart />
							</div>
						</div>
					</div>
					<div className='col-md-8 col-xxl-8'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Giáo viên - Học sinh - Quản lý</h6>
								</div>
								<BaoCaoNhanSuChart />
							</div>
						</div>
					</div>
					<div className='col-md-12 col-xxl-12'>
						<div className='card card-full h-100'>
							<div className='card-inner'>
								<div className='card-title-group pb-3'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Thống kê học sinh theo khối</h6>
									</div>
								</div>
								<BaoCaoHocSinhTheoKhoi />
							</div>
						</div>
					</div>
					<div className='col-md-12 col-xxl-12'>
						<div className='card h-100'>
							<div className='card-inner-group'>
								<div className='card-inner'>
									<div className='card-title-group pb-3'>
										<div className='card-title card-title-sm'>
											<h6 className='title'>Thống kê học sinh theo hệ</h6>
										</div>
									</div>
									<BaoCaoHocSinhTheoHePieChart />
								</div>
							</div>
						</div>
					</div>
					<div className='col-md-6 col-xxl-6'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Kết quả rèn luyện - Hạnh kiểm</h6>
								</div>
								<BaoCaohanhKiemChart />
							</div>
						</div>
					</div>
					<div className='col-md-6 col-xxl-6'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Kết quả rèn luyện - Học lực</h6>
								</div>
								<BaoCaoHocLucChart />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;