/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { AppState } from '../../../context/stores/rootReducers';
import { useSelector } from 'react-redux';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';
import { TruongBaoCaoTongResponse } from '../../../context/models/mega/TruongBaoCaoTongResponse';

const BaoCaoTongBox = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);

	const [dataReport, setDataReport] = useState<TruongBaoCaoTongResponse[]>([]);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectTongAsync(dm_coso_id, nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as TruongBaoCaoTongResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, nam_hoc]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc]);

	const reportGrouped = useMemo(() => {
		if (dm_truong_id <= 0) {
			let result: TruongBaoCaoTongResponse = {
				khoi_total: 0,
				he_total: 0,
				giaovien_total: 0,
				hocsinh_total: 0,
				dm_coso_id: 0,
				id: 0,
				ten_truong: '',
				ten_truong_en: '',
				lop_total: 0
			};
			const truongs = dataReport.filter((map_he) => map_he.dm_coso_id === dm_coso_id);
			if (truongs) {
				truongs.forEach((solieu) => {
					result = {
						...result,
						khoi_total: result.khoi_total + solieu.khoi_total,
						he_total: result.he_total + solieu.he_total,
						giaovien_total: result.giaovien_total + solieu.giaovien_total,
						hocsinh_total: result.hocsinh_total + solieu.hocsinh_total,
					};
				});
			}
			return result;
		}
		return dataReport?.find((x) => x.id === dm_truong_id);
	}, [dataReport, dm_coso_id, dm_truong_id]);
    
    
	return (
		<>
			<div className='nk-block'>
				<div className='row g-gs justify-content-center'>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-primary'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Khối giảng dạy</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.khoi_total || 0} <small className='fs-6'>khối</small>
								</h5>
							</div>
						</div>
					</div>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-warning is-dark'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Chương trình</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.he_total || 0} <small className='fs-6'>hệ</small>
								</h5>
							</div>
						</div>
					</div>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-danger is-dark'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Giảng dạy</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.giaovien_total || 0} <small className='fs-6'>giáo viên</small>
								</h5>
							</div>
						</div>
					</div>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-info is-dark'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Học sinh</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.hocsinh_total || 0} <small className='fs-6'>học sinh</small>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BaoCaoTongBox;
