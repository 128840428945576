import { useSelector } from 'react-redux';
import { DxChart, DxCommonSeriesSettings, DxFormat, DxLabel, DxLegend, DxSeries } from '../../../components/common/DxCharts';
import { createUUID } from '../../../utils/createUUID';
import { AppState } from '../../../context/stores/rootReducers';
import { useLookupTruong } from '../../../components/shared/mega/combobox-truong/useLookupTruong';
import { useMemo } from 'react';

const BaoCaoHocLucChart = () => {
	const { dm_coso_id, dm_truong_id } = useSelector((state: AppState) => state.common);
	const { dm_truong_all } = useLookupTruong();
	const dataSource = useMemo(() => {
		return dm_truong_all
			?.filter((x) => x.dm_coso_id === dm_coso_id)
			?.map((x) => ({
				id: x.id,
				ten_truong: x.ten_truong,
				tot: 0,
				kha: 0,
				trung_binh: 0,
				yeu: 0,
			}));
	}, [dm_truong_all, dm_coso_id]);
	return (
		<>
			<DxChart id={createUUID()} dataSource={dataSource} className='w-100' palette={'Ocean'} height={260}>
				{/* <DxTitle text={`Thống kê nhân sự các trường`}>
					<DxFont color='black' size={18} weight={600} />
				</DxTitle> */}
				<DxCommonSeriesSettings argumentField='ten_truong' type='bar' hoverMode='allArgumentPoints' selectionMode='allArgumentPoints'>
					<DxLabel visible={true}>
						<DxFormat type='fixedPoint' precision={0} />
					</DxLabel>
				</DxCommonSeriesSettings>
				<DxSeries valueField='tot' argumentField='ten_truong' name='Giỏi' type='bar'></DxSeries>
				<DxSeries valueField='kha' argumentField='ten_truong' name='Khá' type='bar'></DxSeries>
				<DxSeries valueField='trung_binh' argumentField='ten_truong' name='Trung bình' type='bar'></DxSeries>
				<DxSeries valueField='yeu' argumentField='ten_truong' name='Yếu' type='bar'></DxSeries>
				<DxSeries valueField='kem' argumentField='ten_truong' name='Kém' type='bar'></DxSeries>
				<DxLegend orientation='horizontal' verticalAlignment='bottom' horizontalAlignment='center' />
			</DxChart>
		</>
	);
};

export default BaoCaoHocLucChart;
