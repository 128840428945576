import { BaseAction } from '../../../base/IBaseActionRespone';
import { AccountInfo, ChangePasswordRequest, LoginRequest, LoginResponse } from '../../../models/my-system/accounts';
import {
	ChangePassword_Failure_Action,
	ChangePassword_Request_Action,
	ChangePassword_Success_Action,
	GetAccount_Failure_Action,
	GetAccount_Request_Action,
	GetAccount_Success_Action,
	Login_Failure_Action,
	Login_Request_Action,
	Login_Success_Action,
	Logout_Request_Action,
	Logout_Success_Action,
	eAccountActionTypeIds,
} from './IAccountActionsTypes';

export const accountActions = {
	//LOGIN
	loginRequest: (request: LoginRequest): Login_Request_Action => BaseAction(eAccountActionTypeIds.LOGIN_REQUEST, request),
	loginSuccess: (data?: LoginResponse): Login_Success_Action => BaseAction(eAccountActionTypeIds.LOGIN_SUCCESS, data),
	loginFailure: (error: Error | string): Login_Failure_Action => BaseAction(eAccountActionTypeIds.LOGIN_FAILURE, error),
	//LOGOUT
	logoutRequest: (): Logout_Request_Action => BaseAction(eAccountActionTypeIds.LOGOUT, undefined),
	logoutSuccess: (): Logout_Success_Action => BaseAction(eAccountActionTypeIds.LOGOUT_SUCCESS, undefined),
	//DETAIL
	getAccountRequest: (): GetAccount_Request_Action => BaseAction(eAccountActionTypeIds.GET_ACCOUNT_REQUEST, undefined),
	getAccountSuccess: (data: AccountInfo | null): GetAccount_Success_Action => BaseAction(eAccountActionTypeIds.GET_ACCOUNT_SUCCESS, data),
	getAccountFailure: (error: Error | string): GetAccount_Failure_Action => BaseAction(eAccountActionTypeIds.GET_ACCOUNT_FAILURE, error),
	//CHANGE
	changePasswordRequest: (request: ChangePasswordRequest): ChangePassword_Request_Action =>
		BaseAction(eAccountActionTypeIds.CHANGE_PASSWORD_REQUEST, request),
	changePasswordSuccess: (message: string): ChangePassword_Success_Action =>
		BaseAction(eAccountActionTypeIds.CHANGE_PASSWORD_SUCCESS, message),
	changePasswordFailure: (error: Error | string): ChangePassword_Failure_Action =>
		BaseAction(eAccountActionTypeIds.CHANGE_PASSWORD_FAILURE, error),
};
