import moment from 'moment';
import { useEffect, useState } from 'react';
import { DxDateBox } from '../../components/base';
import { useSelector } from 'react-redux';
import { AppState } from '../../context/stores/rootReducers';
import { SelectBoxTuVanVien } from '../../components/shared/mega/combo-tuvanvien/SelectBoxTuVanVien';
import BaoCaoCampainChart from './baocao-campain/BaoCaoCampainChart';
import BaoCaoTiLeChuyenDoiChart from './baocao-tile-chuyendoi/BaoCaoTiLeChuyenDoiChart';
import BaoCaoChuyenDoiKH from './baocao-chuyendoi-kh/BaoCaoChuyenDoiKH';
import BaoCaoChuyenDoiTN from './baocao-chuyendoi-tn/BaoCaoChuyenDoiTN';
import BaoCaoOverDateChart from './baocao-overdate/BaoCaoOverDate';
import { data5Source } from './data';
import { rootApi } from '../../context/api/rootApi';

const BaoCaoTuyenSinh = () => {
	const { dm_coso_id } = useSelector((state: AppState) => state.common);
	const [listCustomerRate, setListCustomerRate] = useState([]);
	const [listSourceRate, setListSourceRate] = useState([]);
	const [listLeadCampain, setListLeadCampain] = useState([]);
	const [listconversionRate, setListconversionRate] = useState([]);
	const [listOverDate, setListOverDate] = useState(data5Source);
	const [formData, setFormData] = useState<{
		fromDate: string;
		toDate: string;
		tuvanvien_id: number;
	}>({
		fromDate: moment().startOf('year').format('yyyy-MM-DD'),
		toDate: moment().format('yyyy-MM-DD'),
		tuvanvien_id: 0,
	});

	//Call API get data
	const handleReloadData = async (formData: { fromDate: string; toDate: string; tuvanvien_id: number }) => {
		try {
			const res = await rootApi.baoCao.ReportLead(dm_coso_id, formData.tuvanvien_id, formData.fromDate, formData.toDate);
			if (res.isSuccess && res.result) {
				const data = res.result;
				//get API lấy data tỉ lệ chuyển đổi khách hàng
				setListCustomerRate(data.dataTyleChuyenDoiKhachHang);
				//get API lấy data tỉ lệ chuyển đổi theo nguồn
				setListSourceRate(data.dataTyleChuyenDoiNguon);
				//get API lấy data Lead by Campain
				setListLeadCampain(data.dataLeadByCampaign);
				//get API lấy data Tỉ lệ chuyển đổi
				setListconversionRate(data.dataTyleChuyenDoiNhanVien);
				//get API lấy data OverDate
				// setListOverDate(data5Source)
			}
		} catch (error) {
		} finally {
		}
	};

	useEffect(() => {
		handleReloadData(formData);
	}, [formData, dm_coso_id]);

	const onValueChanged = (type: string) => (value: string | number) => {
		setFormData((prev: { fromDate: string; toDate: string; tuvanvien_id: number }) => {
			return { ...prev, [type]: value };
		});
	};

	return (
		<div className='nk-block '>
			<div className='row g-gs'>
				<div className='col-md-6 col-xxl-4'>
					<div className='card h-100'>
						<div className='card-inner-group'>
							<div className='card-inner'>
								<div className='card-title-group pb-3'>
									<div className='card-title card-title-sm'>
										<h1 className='title'>Tư vấn viên</h1>
									</div>
									<SelectBoxTuVanVien onValueChanged={onValueChanged('tuvanvien_id')} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-6 col-xxl-8'>
					<div className='card h-100'>
						<div className='card-inner-group'>
							<div className='card-inner'>
								<div className='card-title-group pb-3'>
									<div className='card-title card-title-sm'>
										<h1 className='title'>Từ ngày</h1>
									</div>
									<div className='card-tools'>
										<DxDateBox
											name='filter_date'
											onValueChanged={(e) => {
												onValueChanged('fromDate')(moment(e).format('yyyy-MM-DD'));
											}}
											placeholder='Chọn ngày'
											value={formData.fromDate}
										/>
									</div>
									<div className='card-title card-title-sm'>
										<h1 className='title'>Đến ngày</h1>
									</div>
									<div className='card-tools'>
										<DxDateBox
											name='filter_date'
											onValueChanged={(e) => {
												onValueChanged('toDate')(moment(e).format('yyyy-MM-DD'));
											}}
											placeholder='Chọn ngày'
											value={formData.toDate}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-4 col-xxl-4'>
					<div className='card h-100'>
						<div className='card-inner'>
							<div className='card-title card-title-sm'>
								<h6 className='title'>Tỉ lệ chuyển đổi khách hàng</h6>
							</div>
							<BaoCaoChuyenDoiKH data={listCustomerRate} />
						</div>
					</div>
				</div>
				<div className='col-md-4 col-xxl-4'>
					<div className='card h-100'>
						<div className='card-inner'>
							<div className='card-title card-title-sm'>
								<h6 className='title'>Tỉ lệ chuyển đổi theo nguồn</h6>
							</div>
							<BaoCaoChuyenDoiTN data={listSourceRate} />
						</div>
					</div>
				</div>
				<div className='col-md-4 col-xxl-4'>
					<div className='card h-100'>
						<div className='card-inner'>
							<div className='card-title card-title-sm'>
								<h6 className='title' style={{ height: 16 }}>
									{' '}
									{`    `}
								</h6>
							</div>
							<BaoCaoOverDateChart data={listOverDate} />
						</div>
					</div>
				</div>
				<div className='col-md-4 col-xxl-4'>
					<div className='card h-100'>
						<div className='card-inner'>
							<div className='card-title card-title-sm'>
								<h6 className='title'>Tỉ lệ chuyển đổi</h6>
							</div>
							<BaoCaoTiLeChuyenDoiChart data={listconversionRate} />
						</div>
					</div>
				</div>
				<div className='col-md-8 col-xxl-8'>
					<div className='card h-100'>
						<div className='card-inner'>
							<div className='card-title card-title-sm'>
								<h6 className='title'>Lead by campain</h6>
							</div>
							<BaoCaoCampainChart data={listLeadCampain} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BaoCaoTuyenSinh;
