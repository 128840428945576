import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DxConnectorPieChart, DxLabelPieChart, DxLegendPieChart, DxPieChart, DxSeriesPieChart } from '../../../components/common/DxCharts';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { DiemDanhResponse } from '../../../context/models/mega/DiemDanhResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';

interface BaoCaoDiemDanhToanTruongPieChartProps {
	ngay_diem_danh: string;
}

const energySources = [
	{ value: 'chua_diem_danh', name: 'Chưa điểm danh' },
	{ value: 'dung_gio', name: 'Đúng giờ' },
	{ value: 'den_muon', name: 'Đến muộn' },
	{ value: 'vang_co_phep', name: 'Vắng có phép' },
	{ value: 'vang_khong_phep', name: 'Vắng không phép' },
];

const BaoCaoDiemDanhToanTruongPieChart = (props: BaoCaoDiemDanhToanTruongPieChartProps) => {
	const { ngay_diem_danh } = props;
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);

	const [dataReport, setDataReport] = useState<DiemDanhResponse[]>([]);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectDiemDanhAsync(dm_coso_id, dm_truong_id, nam_hoc, ngay_diem_danh);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as DiemDanhResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc, ngay_diem_danh]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dm_coso_id, dm_truong_id, nam_hoc, ngay_diem_danh]);

	const soLieuGrouped = useMemo(() => {
		let result: any = { chua_diem_danh: 0, dung_gio: 0, den_muon: 0, vang_co_phep: 0, vang_khong_phep: 0 };
		dataReport.forEach((solieu) => {
			result = {
				...result,
				chua_diem_danh: result.chua_diem_danh + solieu.chua_diem_danh,
				dung_gio: result.dung_gio + solieu.dung_gio,
				den_muon: result.den_muon + solieu.den_muon,
				vang_co_phep: result.vang_co_phep + solieu.vang_co_phep,
				vang_khong_phep: result.vang_khong_phep + solieu.vang_khong_phep,
			};
		});
		return energySources.map((x) => ({
			name: x.name,
			total: result[x.value] || 0,
		}));
	}, [dataReport]);

	return (
		<>
			<DxPieChart
				id={createUUID()}
				palette='Dark Violet'
				dataSource={soLieuGrouped}
				// customizePoint={(e) => {
				// 	return { color: `${e.data.color}` };
				// }}
				type='doughnut'
				centerRender={(pieChart) => {
					return (
						<svg>
							<circle cx='100' cy='100' r={pieChart.getInnerRadius() - 6} fill='#CBCCFF'></circle>
							{/* <image href={getImagePath(country)} x="70" y="58" width="60" height="40" /> */}
							<text textAnchor='middle' x='100' y='105' style={{ fill: '#494949', fontWeight: 600 }}>
								<tspan x='100'>Toàn trường</tspan>
							</text>
						</svg>
					);
				}}
				resolveLabelOverlapping={'shift'}
				className='w-100'
				height={320}
			>
				{/* <DxTitlePieChart text='Toàn trường'>
					<DxFontPieChart size={18} weight={600} />
				</DxTitlePieChart> */}
				<DxLegendPieChart
					orientation='horizontal'
					itemTextPosition='right'
					horizontalAlignment='center'
					verticalAlignment='bottom'
					columnCount={5}
				/>
				<DxSeriesPieChart argumentField='name' valueField='total'>
					<DxLabelPieChart visible={true} position='columns'>
						<DxConnectorPieChart visible={true} width={0.5} />
					</DxLabelPieChart>
				</DxSeriesPieChart>
			</DxPieChart>
		</>
	);
};

export default BaoCaoDiemDanhToanTruongPieChart;
