import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DxDateBox } from '../../components/base';
import { AppState } from '../../context/stores/rootReducers';
import { useSelector } from 'react-redux';
import { rootApi } from '../../context/api/rootApi';
import { DxSelectBox } from '../../components/base/DxSelectBox';
import DxTable, { DxTableColumn } from '../../components/common/DxTable';
import { DxGroupItem, DxSummary, DxGrouping } from '../../components/common/DxTable/dxtable-control/DxTableControl';
import { BaseResponse } from '../../context/base/BaseResponse';
import { TuyenDuongResponse } from '../../context/models/mega/TuyenDuongResponse';
import { BusBaoCaoDiemDanhItemResponse } from '../../context/models/mega/BusBaoCaoDiemDanhItemResponse';
import { BusBaoCaoItemResponse } from '../../context/models/mega/BusBaoCaoItemResponse';
import BaoCaoChuyenDoiTN from '../baobao-tuyensinh/baocao-chuyendoi-tn/BaoCaoChuyenDoiTN';
import BaoCaoOverDateChart from '../baobao-tuyensinh/baocao-overdate/BaoCaoOverDate';

const BaoCaoDiemDanhBusPage = () => {
	const [dsTuyenDuongs, setDSTuyenDuongs] = useState<TuyenDuongResponse[]>([]);
	const { nam_hoc, dm_coso_id } = useSelector((state: AppState) => state.common);
	const [listSourceRate1, setListSourceRate1] = useState<BusBaoCaoItemResponse[]>([]);
	const [listSourceRate2, setListSourceRate2] = useState<BusBaoCaoItemResponse[]>([]);
	const [listSourceRate3, setListSourceRate3] = useState<BusBaoCaoItemResponse[]>([]);
	const [listSourceRate4, setListSourceRate4] = useState<BusBaoCaoItemResponse[]>([]);
	const [filter, setFilter] = useState({
		dm_coso_id: dm_coso_id,
		bus_tuyenduong_id: 0,
		nam_hoc: nam_hoc,
		ngay_diem_danh: moment().format(),
	});
	const handleReloadPhanTuyens = async () => {
		const ngay = moment(filter.ngay_diem_danh).format('DDMMYYYY');
		console.log(ngay);
		const res: BaseResponse = await rootApi.baoCao.SelectBaoCaoDiemDanhAsync(dm_coso_id, nam_hoc, filter.bus_tuyenduong_id, ngay);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BusBaoCaoDiemDanhItemResponse[];
			var chieuDi = [{name: "Tổng số xe đã đến trường", so_luong: listCategory[0].soDaDenTruong},
			{name: "Tổng số xe chưa đến trường", so_luong: (listCategory[0].tongSoXe-listCategory[0].soDaDenTruong)}]
			setListSourceRate1(chieuDi);
			var chieuVe = [{name: "Tổng số xe đã hoàn thành", so_luong: listCategory[0].soXeHoanThanh},
			{name: "Tổng số xe chưa hoàn thành", so_luong: (listCategory[0].tongSoXe-listCategory[0].soXeHoanThanh)}]
			setListSourceRate2(chieuVe);
			var hocSinhVang = [{name: "Tổng số học sinh vắng", so_luong: listCategory[0].vangChieuDi},
			{name: "Tổng số học sinh lên xe", so_luong: (listCategory[0].tongSoHocSinh-listCategory[0].vangChieuDi)}]
			setListSourceRate3(hocSinhVang);
			var trangThai = [{name: "Tổng số học lên xe đến trường", so_luong: listCategory[0].lenXeDi},
			{name: "Tổng số học sinh đã đến trường", so_luong: listCategory[0].daDenTruong},
			{name: "Tổng số học sinh lên xe về", so_luong: listCategory[0].lenXeVe},
			{name: "Tổng số học sinh đã đến nhà", so_luong: listCategory[0].daDenNha}]
			setListSourceRate4(trangThai);
		}
	};

	useEffect(() => {
		setFilter((prev: any) => ({ ...prev, dm_coso_id: dm_coso_id, nam_hoc: nam_hoc }));
		handleReloadPhanTuyens();
	}, [nam_hoc, dm_coso_id, filter.ngay_diem_danh]);


	return (
		<>
			<div className='nk-block'>
				<div className='row g-gs'>
					<div className='col-md-12 col-xxl-12'>
						<div className='card card-full h-100'>
							<div className='card-inner-group'>
								<div className='card-inner'>
									<div className='card-title-group'>
										<div className='card-title card-title-sm'>
											<h6 className='title'>Ngày điểm danh</h6>
										</div>
										<div className='card-tools'>
											<DxDateBox
												name='filter_date'
												onValueChanged={(e) => {
													setFilter((prev) => ({ ...prev, ngay_diem_danh: e || 0 }));
												}}
												placeholder='Chọn ngày điểm danh'
												value={filter.ngay_diem_danh}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
		
						<div className='col-md-6 col-xxl-6'>
							<div className='card h-100'>
								<div className='card-inner'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Thống kê chiều đi</h6>
									</div>
									<BaoCaoChuyenDoiTN data={listSourceRate1} />
								</div>
							</div>
						</div>
						<div className='col-md-6 col-xxl-6'>
							<div className='card h-100'>
								<div className='card-inner'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Thống kê chiều về</h6>
									</div>
									<BaoCaoChuyenDoiTN data={listSourceRate2} />
								</div>
							</div>
						</div>
					
		
						<div className='col-md-6 col-xxl-6'>
							<div className='card h-100'>
								<div className='card-inner'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Thống kê số học sinh vắng</h6>
									</div>
									<BaoCaoChuyenDoiTN data={listSourceRate3} />
								</div>
							</div>
						</div>
						<div className='col-md-6 col-xxl-6'>
							<div className='card h-100'>
								<div className='card-inner'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Thống kê số lượng học sinh</h6>
									</div>
									<BaoCaoOverDateChart data={listSourceRate4} />
								</div>
							</div>
						</div>
					
				</div>
			</div>
		</>
	);
};

export default BaoCaoDiemDanhBusPage;
