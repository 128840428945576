import { useDispatch, useSelector } from 'react-redux';
import { DxSelectBox } from '../../../base/DxSelectBox';
import { useLookupTuVanVien } from './useLookupSelectTuVanVien';
import { AppState } from '../../../../context/stores/rootReducers';
import { actions } from '../../../../context/stores/rootActions';
import { createUUID } from '../../../../utils/createUUID';
import { useCallback, useEffect, useState } from 'react';
import { rootApi } from '../../../../context/api/rootApi';

interface SelectBoxTuVanVienProps {
	onValueChanged: (value: number) => void;
}

export const SelectBoxTuVanVien = ({ onValueChanged }: SelectBoxTuVanVienProps) => {
	// const dispatch = useDispatch();
	const { dm_coso_id } = useSelector((state: AppState) => state.common);
	// const { dm_tu_van_viens } = useLookupTuVanVien();
	const [dm_tu_van_viens, set_dm_tu_van_viens] = useState([]);
	const [dm_tu_van_vien_id, set_dm_tu_van_vien_id] = useState<number>(0);
	//FIXME:Call API lay danh sach tu van vien theo co so
	const getAllTuVanVienByCoSo = useCallback(async () => {
		try {
			const res = await rootApi.baoCao.SelectTuVanVien(dm_coso_id);
			if (res.isSuccess && res.result) {
				set_dm_tu_van_viens(res.result);
			} else {
				set_dm_tu_van_viens([]);
			}
			// const res = await userApi.SelectTuVanVien(dm_coso_id);
			// if (res.is_success) {
			//   set_dm_tu_van_viens(res.data);
			// } else {
			//   set_dm_tu_van_viens([]);
			// }
		} catch (error) {}
	}, [dm_coso_id]);

	useEffect(() => {
		getAllTuVanVienByCoSo();
	}, [dm_coso_id]);
	return (
		<DxSelectBox
			dataSource={dm_tu_van_viens}
			value={dm_tu_van_vien_id}
			onValueChanged={(value) => {
				set_dm_tu_van_vien_id(value || 0);
				onValueChanged(value || 0);
				// dispatch(actions.common.changeTUVANVIEN(value || 0));
			}}
			width={'100%'}
			valueExpr='id'
			displayExpr='full_name'
			placeholder='Chọn Tư vấn vien'
			name={`tuvanvien_${createUUID()}`}
			// label='Năm làm việc'
			// labelSmall={true}
		/>
	);
};
