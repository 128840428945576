import { NavLink } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import logo_cms_small from '../../assets/images/logo-dark-small2x.png';
import logo_cms from '../../assets/images/logo-dark2x.png';
import { MenuRender } from './Menu';

type ISideBarProps = {
	isShowSideBarMobile: boolean | false;
	isShowSideBar: boolean | false;
	showSideBar: () => void;
	setShowSidebarMobile: () => void;
};

const Sidebar = (props: ISideBarProps) => {
	const { isShowSideBar, isShowSideBarMobile, showSideBar, setShowSidebarMobile } = props;
	return (
		<>
			<div
				className={`nk-sidebar is-light nk-sidebar-fixed is-light ${isShowSideBar ? 'is-compact' : ''} ${
					isShowSideBarMobile ? 'nk-sidebar-active' : ''
				}`}
			>
				<div className='nk-sidebar-element nk-sidebar-head justify-content-center'>
					<div className='nk-sidebar-brand'>
						<NavLink to='https://portal.megaedu.net.vn/' className='logo-link nk-sidebar-logo'>
							<img className='logo-light logo-img' src={'https://portal.megaedu.net.vn/images/logo.png'} alt='logo' />
							<img className='logo-dark logo-img' src={'https://portal.megaedu.net.vn/images/logo.png'} alt='logo-dark' />
							<img className='logo-small logo-img logo-img-small' src={'https://portal.megaedu.net.vn/images/logo.png'} alt='logo-small' />
						</NavLink>
					</div>
					<div className='nk-menu-trigger ms-auto me-n2'>
						<button
							type='button'
							className='nk-quick-nav-icon btn btn-round btn-icon d-xl-none fs-14px'
							onClick={() => setShowSidebarMobile()}
						>
							{isShowSideBarMobile ? <em className='icon ni ni-chevrons-left'></em> : <em className='icon ni ni-chevrons-right'></em>}
						</button>
						<button
							type='button'
							className='nk-quick-nav-icon btn btn-round btn-icon d-none d-xl-inline-flex fs-14px'
							onClick={() => showSideBar()}
						>
							{isShowSideBar ? <em className='icon ni ni-chevrons-right'></em> : <em className='icon ni ni-chevrons-left'></em>}
						</button>
					</div>
				</div>
				<div className='nk-sidebar-element'>
					<div className='nk-sidebar-content'>
						<SimpleBar forceVisible='y' autoHide={true} className='nk-sidebar-menu'>
							<ul className='nk-menu'>
								<MenuRender />
							</ul>
						</SimpleBar>
					</div>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
