import { useMemo, useState } from 'react';
import { MenuResponse } from '../../../context/models/my-system/accounts/MenuResponse';
import { useAuth } from '../../../utils/AuthProvider';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

interface MenuTree {
	id: number;
	name: string;
	path: string;
	icon: string;
	parent_id: number;
	sort_order: number;
	isActive: boolean;
	childs?: MenuTree[];
}


const _menuItems: MenuResponse[] = [
	{
		id: 1,
		icon: 'icon ni ni-home-fill',
		name: 'Trang chủ',
		path: '/',
		parent_id: 0,
		sort_order: 1
	},
	{
		id: 6,
		icon: 'icon ni ni-calendar',
		name: 'Điểm danh',
		path: '/diem-danh',
		parent_id: 0,
		sort_order: 2
	},
	{
		id: 2,
		icon: 'icon ni ni-users',
		name: 'Nhân sự',
		path: '/giao-vien',
		parent_id: 0,
		sort_order: 3
	},
	{
		id: 3,
		icon: 'icon ni ni-user-list',
		name: 'Học sinh',
		path: '/hoc-sinh',
		parent_id: 0,
		sort_order: 4
	},
	{
		id: 4,
		icon: 'icon ni ni-file-check-fill',
		name: 'Kết quả học tập',
		path: '/ket-qua-hoc-tap',
		parent_id: 0,
		sort_order: 5
	},
	// {
	// 	id: 5,
	// 	icon: 'icon ni ni-building-fill',
	// 	name: 'Thống kê khối/lớp',
	// 	path: '/khoi-giang-day',
	// 	parent_id: 0,
	// 	sort_order: 6
	// },
	{
		id: 7,
		icon: 'icon ni ni-building-fill',
		name: 'Báo cáo tuyển sinh',
		path: '/bao-cao-tuyen-sinh',
		parent_id: 0,
		sort_order: 7
	},
	{
		id: 8,
		icon: 'icon ni ni-building-fill',
		name: 'Báo cáo điểm danh bus',
		path: '/bao-cao-diem-danh',
		parent_id: 0,
		sort_order: 8
	},
];

const buildTree = (parentId: number, menus: MenuResponse[]) => {
	return menus
		.filter((m) => m.parent_id === parentId)
		.map((menu) => {
			const menuTree: MenuTree = {
				...menu,
				isActive: false,
				childs: buildTree(menu.id, menus),
			};
			return menuTree;
		});
};

export const MenuRender = () => {
	const menuTrees: MenuTree[] = useMemo(() => {
		return _menuItems ? buildTree(0, _menuItems) : [];
	}, [_menuItems]);
	return <>{menuTrees && menuTrees.length > 0 && menuTrees.map((group) => <MenuGroup key={group.id} group={group} />)}</>;
};

const MenuGroup = ({ group }: { group: MenuTree }) => {
	return <>{group && <MenuItem menu={group} />}</>;
};

const MenuSub = ({ menus }: { menus: MenuTree[] }) => {
	return (
		<>
			{menus && menus.length > 0 && (
				<>
					<ul className='nk-menu-sub'>
						{menus.map((menu, index) => {
							return <MenuItem key={menu.id} menu={menu} />;
						})}
					</ul>
				</>
			)}
		</>
	);
};

const MenuItem = ({ menu }: { menu: MenuTree }) => {
	const [isActive, setIsExpanded] = useState(menu.isActive);
	const location = useLocation();
	const currentActive = useMemo(() => {
		return matchPath(location.pathname, menu.path)?.pathname === menu.path;
	}, [location, menu]);

	return (
		<li
			className={`nk-menu-item ${menu.childs && menu.childs.length > 0 ? 'has-sub' : ''} ${
				isActive || currentActive ? 'active current-page' : ''
			}`}
		>
			<NavLink
				to={menu.path}
				className={`nk-menu-link ${menu.childs && menu.childs.length > 0 ? 'nk-menu-toggle' : ''}`}
				title={menu.name}
				onClick={(e) => {
					if (menu.childs && menu.childs.length > 0) {
						e.preventDefault();
						if (!currentActive) setIsExpanded((prev) => !prev);
					}
				}}
			>
				<span className='nk-menu-icon'>
					<em className={menu.icon || 'icon ni ni-list-thumb'}></em>
				</span>
				<span className='nk-menu-text'>{menu.name}</span>
			</NavLink>
			{menu.childs && <MenuSub menus={menu.childs} />}
		</li>
	);
};
