import { DxFunnel, DxLabel, DxTooltip } from '../../../components/common/DxCharts';

const formatLabel = (arg: any) => {
	return `<span class="label">${arg.item.value}</span><br/>${arg.item.argument}`;
};

interface BaoCaoChuyenDoiKHProps {
	data: any[];
}

const BaoCaoChuyenDoiKH = ({ data }: BaoCaoChuyenDoiKHProps) => {
	return (
		<DxFunnel id='chart' dataSource={data} palette='Soft Pastel' argumentField='name' valueField='so_luong'>
			{/* <Size width={400} height={350} /> */}
			{/* <DxTitlePieChart text={'Tỉ lệ chuyển đổi khách hàng'}>
				<DxFontPieChart size={12} weight={600} />
			</DxTitlePieChart> */}
			<DxTooltip enabled={true} format='fixedPoint' />
			<DxLabel visible={true} position='inside' backgroundColor='none' customizeText={formatLabel} />
		</DxFunnel>
	);
};

export default BaoCaoChuyenDoiKH;
