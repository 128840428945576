import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../context/stores/rootReducers';

interface PreviewParams {
    dm_truong_id: number;
    dm_coso_id: number;
    nam_hoc: string;
    hoc_ky: number;
}

const PhoDiemPage = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, hoc_ky } = useSelector((state: AppState) => state.common);
	const urlPreview = useMemo(() => {
        const paramObj : PreviewParams = {
            dm_truong_id: dm_truong_id,
            dm_coso_id: dm_coso_id,
            nam_hoc: nam_hoc,
            hoc_ky: hoc_ky
        };
        const urlParams = new URLSearchParams(paramObj as any);
		return `https://sis.megaedu.net.vn/pho-diem?${urlParams}`;
	}, [dm_coso_id, dm_truong_id, nam_hoc, hoc_ky]);
	return (
		<>
			<div className='nk-block'>
				<div className='row g-gs'>
					<div className='col-md-12 col-xxl-12'>
						<div className='card'>
							<div className='card-inner'>
								<iframe src={urlPreview} className='w-100' height={window.innerHeight - 180} allowFullScreen={true}></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PhoDiemPage;
