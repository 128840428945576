import { all, call, put, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from '../../rootActions';
import { eCategoryActionTypeIds } from './ICategoryActionTypes';
import { BaseResponse } from '../../../base/BaseResponse';
import { rootApi } from '../../../api/rootApi';
import { dm_coso } from '../../../models/mega/dm_coso';
import { dm_truong } from '../../../models/mega/dm_truong';
import { dm_khoi } from '../../../models/mega/dm_khoi';
import { dm_namhoc } from '../../../models/mega/dm_namhoc';
import { dm_tu_van_viens } from '../../../models/mega/dm_tuvanvien';

const _sagaApi = rootApi.category;

function* watchOnLoadCOSOCategory() {
	yield takeLatest(eCategoryActionTypeIds.GET_COSO_REQUEST, function* () {
		try {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectCoSoAsync]);
			if (res.isSuccess && res.result) {
				const listCategory = res.result as dm_coso[];
				yield put(actions.mega.category.getCOSOSuccess(listCategory));
			} else {
				yield put(actions.mega.category.getCOSOFailure(res.message));
			}
		} catch (error: any) {
			yield put(actions.mega.category.getCOSOFailure(error || 'Có lỗi'));
		}
	});
}

function* watchOnLoadTRUONGCategory() {
	yield takeLatest(eCategoryActionTypeIds.GET_TRUONG_REQUEST, function* () {
		try {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectTruongAsync]);
			if (res.isSuccess && res.result) {
				const listCategory = res.result as dm_truong[];
				yield put(actions.mega.category.getTRUONGSuccess(listCategory));
			} else {
				yield put(actions.mega.category.getTRUONGFailure(res.message));
			}
		} catch (error: any) {
			yield put(actions.mega.category.getTRUONGFailure(error || 'Có lỗi'));
		}
	});
}

function* watchOnLoadKHOICategory() {
	yield takeLatest(eCategoryActionTypeIds.GET_KHOI_REQUEST, function* () {
		try {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectKhoiAsync]);
			if (res.isSuccess && res.result) {
				const listCategory = res.result as dm_khoi[];
				yield put(actions.mega.category.getKHOISuccess(listCategory));
			} else {
				yield put(actions.mega.category.getKHOIFailure(res.message));
			}
		} catch (error: any) {
			yield put(actions.mega.category.getKHOIFailure(error || 'Có lỗi'));
		}
	});
}

function* watchOnLoadNAMHOCCategory() {
	yield takeLatest(eCategoryActionTypeIds.GET_NAMHOC_REQUEST, function* () {
		try {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectNamHocAsync]);
			if (res.isSuccess && res.result) {
				const listCategory = res.result as dm_namhoc[];
				yield put(actions.mega.category.getNAMHOCSuccess(listCategory));
			} else {
				yield put(actions.mega.category.getNAMHOCFailure(res.message));
			}
		} catch (error: any) {
			yield put(actions.mega.category.getNAMHOCFailure(error || 'Có lỗi'));
		}
	});
}

function* watchOnLoadTUVANVIENCategory() {
	yield takeLatest(eCategoryActionTypeIds.GET_TUVANVIEN_REQUEST, function* () {
		try {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectTuVanViencAsync]);
			if (res.isSuccess && res.result) {
				const listCategory = res.result as dm_tu_van_viens[];
				yield put(actions.mega.category.getTUVANVIENSuccess(listCategory));
			} else {
				yield put(actions.mega.category.getTUVANVIENFailure(res.message));
			}
		} catch (error: any) {
			yield put(actions.mega.category.getTUVANVIENFailure(error || 'Có lỗi'));
		}
	});
}

function* categorySaga() {
	yield all([fork(watchOnLoadCOSOCategory)]);
	yield all([fork(watchOnLoadTRUONGCategory)]);
	yield all([fork(watchOnLoadKHOICategory)]);
	yield all([fork(watchOnLoadNAMHOCCategory)]);
	yield all([fork(watchOnLoadNAMHOCCategory)]);
	yield all([fork(watchOnLoadTUVANVIENCategory)]);
}

export default categorySaga;
