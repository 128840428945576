import { DxChart, DxLegend, DxSeries, DxTooltip } from '../../../components/common/DxCharts';

interface BaoCaoOverDateChartProps {
	data: any[];
}

const customizeTooltip = (arg: any) => {
	return {
		text: `${arg.argumentText}: ${arg.valueText}`,
	};
};

const BaoCaoOverDateChart = ({ data }: BaoCaoOverDateChartProps) => {
	return (
		<DxChart id='chart' dataSource={data}>
			{/* <DxTitlePieChart text={'Tỉ lệ chuyển đổi'}>
				<DxFontPieChart size={12} weight={600} />
			</DxTitlePieChart> */}
			{/* <Size width={400} height={350} /> */}
			<DxSeries valueField='so_luong' argumentField='name' name='Số lượng' type='bar' color='rgb(156, 99, 255)' />
			<DxLegend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='right' />
			<DxTooltip enabled={true} location='edge' customizeTooltip={customizeTooltip} />
		</DxChart>
	);
};

export default BaoCaoOverDateChart;
