import { useState, useCallback, useEffect } from 'react';
import { DxConnectorPieChart, DxLabelPieChart, DxLegendPieChart, DxPieChart, DxSeriesPieChart } from '../../../components/common/DxCharts';
import { createUUID } from '../../../utils/createUUID';
import { useSelector } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';

type BaoCaoLopTheoKhoiResponse = {
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	lop_total: number;
};

const BaoCaoLopTheoKhoiPieChart = () => {
	const customizeText = (arg: any) => {
		return `${arg.valueText} (${arg.percentText})`;
	};
	const [dataReport, setDataReport] = useState<BaoCaoLopTheoKhoiResponse[]>([]);
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.GetBaoCaoLopTheoKhoiAsync(dm_coso_id, dm_truong_id, nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoLopTheoKhoiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	return (
		<>
			<DxPieChart
				id={createUUID()}
				palette='Dark Violet'
				dataSource={dataReport}
				className='w-100'
				width={300}
				resolveLabelOverlapping={'shift'}
			>
				<DxLegendPieChart
					orientation='horizontal'
					itemTextPosition='right'
					horizontalAlignment='center'
					verticalAlignment='bottom'
					columnCount={5}
				/>
				{/* <DxTitlePieChart text='Thống kê lớp học theo khối'>
					<DxFontPieChart size={18} />
				</DxTitlePieChart> */}
				<DxSeriesPieChart argumentField='ten_khoi' valueField='lop_total'>
					<DxLabelPieChart visible={true} position='columns' customizeText={customizeText}>
						<DxConnectorPieChart visible={true} width={0.5} />
					</DxLabelPieChart>
				</DxSeriesPieChart>
			</DxPieChart>
		</>
	);
};

export default BaoCaoLopTheoKhoiPieChart;
