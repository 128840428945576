import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { ChangePasswordRequest } from '../../context/models/my-system/accounts';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { useAuth } from '../../utils/AuthProvider';
import { HeaderSelectBoxCoSo, HeaderSelectBoxHocKy, HeaderSelectBoxNamHoc, HeaderSelectBoxTruong } from '../../components/shared/mega';

interface IHeaderProps {
	showSideBar: () => void;
}
const Header = (props: IHeaderProps) => {
	const { account, logout } = useAuth();
	const dispatch = useDispatch();
	const { showSideBar } = props;
	const [isChangePassword, setChangePassword] = useState(false);
	const [userDropdown, setUserDropdown] = useState(false);
	const { accountStatus } = useSelector((state: AppState) => state.account);

	const changePassword = (data: ChangePasswordRequest) => {
		dispatch(actions.account.changePasswordRequest(data));
	};

	return (
		<>
			<div className='nk-header is-light'>
				<div className='container-xl wide-xl'>
					<div className='nk-header-wrap header-bg'>
						<div className='nk-menu-trigger d-lg-none ms-n1 pe-2'>
							<button type='button' className='nk-quick-nav-icon btn btn-round btn-icon fs-14px' onClick={() => showSideBar()}>
								<em className='icon ni ni-menu'></em>
							</button>
						</div>
						<div className='nk-menu-trigger'>
							<ul className='nk-quick-nav'>
								<li className='nav-item'>
									<HeaderSelectBoxCoSo />
								</li>
								<li className='nav-item'>
									<HeaderSelectBoxTruong />
								</li>
								<li className='nav-item'>
									<HeaderSelectBoxNamHoc />
								</li>
								<li className='nav-item'>
									<HeaderSelectBoxHocKy />
								</li>
							</ul>
						</div>
						<div className='nk-header-tools'>
							<ul className='nk-quick-nav'>
								{account && (
									<>
										<li className='dropdown user-dropdown'>
											<button type='button' className='btn nav-link me-n1' onClick={() => setUserDropdown((prev) => !prev)}>
												<div className='user-toggle'>
													<div className='user-avatar sm'>
														{account.avatar ? (
															<>
																<em className='icon ni ni-user-alt'></em>
															</>
														) : (
															<>
																<em className='icon ni ni-user-alt'></em>
															</>
														)}
													</div>
													<div className='user-info d-none d-xl-block'>
														<div className='user-status align-left'>Xin chào</div>
														<div className='user-name dropdown-indicator align-left'>{account.full_name}</div>
													</div>
												</div>
											</button>
											<div className={`dropdown-menu dropdown-menu-md dropdown-menu-end ${userDropdown ? 'show' : ''}`}>
												<div className='dropdown-inner user-card-wrap bg-lighter d-none d-md-block'>
													<div className='user-card'>
														<div className='user-avatar'>
															{account.avatar ? (
																<>
																	<em className='icon ni ni-user-alt'></em>
																</>
															) : (
																<>
																	<em className='icon ni ni-user-alt'></em>
																</>
															)}
														</div>
														<div className='user-info'>
															<span className='lead-text'>{account.full_name}</span>
															<span className='sub-text'>{account.user_name}</span>
														</div>
													</div>
												</div>
												{/* <div className='dropdown-inner'>
													<ul className='link-list'>
														<li>
															<button
																type='button'
																// onClick={() => setChangePassword((prev) => !prev)}
																className='btn nav-link text-primary'
															>
																<em className='icon ni ni-unlock'></em>
																<span>Đổi mật khẩu</span>
															</button>
														</li>
													</ul>
												</div> */}
												<div className='dropdown-inner'>
													<ul className='link-list'>
														<li>
															<button type='button' onClick={() => logout()} className='btn nav-link text-danger'>
																<em className='icon ni ni-signout'></em>
																<span>Đăng xuất</span>
															</button>
														</li>
													</ul>
												</div>
											</div>
										</li>
									</>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
