import { BaseAction } from '../../../base/IBaseActionRespone';
import {
	CommonAction_CHANGE_COSO_MODE,
	CommonAction_CHANGE_HOCKY_MODE,
	CommonAction_CHANGE_MENU_MODE,
	CommonAction_CHANGE_NAMHOC_MODE,
	CommonAction_CHANGE_TRUONG_MODE,
	CommonAction_CHANGE_TUVANVIEN_MODE,
	CommonAction_HEADER_RESET_SELECTED,
	eCommonActionTypeIds,
} from './ICommonActionTypes';

export const commonActions = {
	changeCOSO: (mode: number): CommonAction_CHANGE_COSO_MODE => BaseAction(eCommonActionTypeIds.CHANGE_COSO_MODE, mode),
	changeTRUONG: (mode: number): CommonAction_CHANGE_TRUONG_MODE => BaseAction(eCommonActionTypeIds.CHANGE_TRUONG_MODE, mode),
	changeMENU: (mode: string): CommonAction_CHANGE_MENU_MODE => BaseAction(eCommonActionTypeIds.CHANGE_MENU_MODE, mode),
	changeNAMHOC: (mode: string): CommonAction_CHANGE_NAMHOC_MODE => BaseAction(eCommonActionTypeIds.CHANGE_NAMHOC_MODE, mode),
	changeHOCKY: (mode: number): CommonAction_CHANGE_HOCKY_MODE => BaseAction(eCommonActionTypeIds.CHANGE_HOCKY_MODE, mode),
	changeTUVANVIEN: (mode: number): CommonAction_CHANGE_TUVANVIEN_MODE => BaseAction(eCommonActionTypeIds.CHANGE_TUVANVIEN_MODE, mode),
	headerResetSelected: (): CommonAction_HEADER_RESET_SELECTED => BaseAction(eCommonActionTypeIds.HEADER_RESET_SELECTED, undefined),
};
