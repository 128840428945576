/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { AppState } from '../../../context/stores/rootReducers';
import { useSelector } from 'react-redux';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';
import { TruongBaoCaoTongResponse } from '../../../context/models/mega/TruongBaoCaoTongResponse';

interface BaoCaoNhanSuResponse {
	dm_truong_id: number;
	ten_truong: string;
	ten_truong_en: string;
	giaovien_total: number;
	nhanvien_total: number;
	quanly_total: number;
}

const BaoCaoTongBox = () => {
	const [dataReport, setDataReport] = useState<BaoCaoNhanSuResponse[]>([]);
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.GetBaoCaoNhanSuAsync(dm_coso_id, dm_truong_id, nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoNhanSuResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	const truongTotals = useMemo(() => {
		return dataReport?.map((x) => ({ ...x, nhanvien_total: x.nhanvien_total || 0, all_total: x.giaovien_total + x.quanly_total }));
	}, [dataReport]);

	const reportGrouped = useMemo(() => {
		if (dm_truong_id <= 0) {
			let result: any = {
				all_total: 0,
				giaovien_total: 0,
				nhanvien_total: 0,
				quanly_total: 0,
			};
			if (truongTotals) {
				truongTotals.forEach((solieu) => {
					result = {
						...result,
						all_total: result.all_total + solieu.all_total,
						giaovien_total: result.giaovien_total + solieu.giaovien_total,
						nhanvien_total: result.nhanvien_total + solieu.nhanvien_total,
						quanly_total: result.quanly_total + solieu.quanly_total,
					};
				});
			}
			return result;
		}
		return truongTotals?.find((x) => x.dm_truong_id === dm_truong_id);
	}, [truongTotals, dm_coso_id, dm_truong_id]);

	return (
		<>
			<div className='nk-block'>
				<div className='row g-gs justify-content-center'>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-primary'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Tổng số</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.all_total} <small className='fs-6'>nhân sự</small>
								</h5>
							</div>
						</div>
					</div>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-warning is-dark'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Ban quản lý</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.quanly_total} <small className='fs-6'>quản lý</small>
								</h5>
							</div>
						</div>
					</div>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-danger is-dark'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Giảng dạy</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.giaovien_total} <small className='fs-6'>giáo viên</small>
								</h5>
							</div>
						</div>
					</div>
					<div className='col-sm-3 col-xxl-3'>
						<div className='card card-full bg-info is-dark'>
							<div className='card-inner'>
								<div className='d-flex align-items-center justify-content-between mb-1'>
									<div className='fs-4 text-white text-opacity-75 mb-0'>Bộ phận khác</div>
								</div>
								<h5 className='fs-1 text-white'>
									{reportGrouped?.nhanvien_total} <small className='fs-6'>nhân viên</small>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BaoCaoTongBox;
