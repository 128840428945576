import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';
import { createUUID } from '../../../../utils/createUUID';
import { DxSelectBox } from '../../../base/DxSelectBox';
import { useLookupNamHoc } from './useLookupNamHoc';

export const HeaderSelectBoxNamHoc = () => {
	const dispatch = useDispatch();
	const { nam_hoc } = useSelector((state: AppState) => state.common);
	const { dm_namhoc_all } = useLookupNamHoc();
	return (
		<DxSelectBox
			dataSource={dm_namhoc_all}
			value={nam_hoc}
               width={110}
			onValueChanged={(value) => {
				dispatch(actions.common.changeNAMHOC(value || 0));
			}}
			valueExpr='nam_hoc'
			displayExpr='nam_hoc'
			placeholder='Chọn Năm học'
			name={`namhoc_${createUUID()}`}
			// label='Năm làm việc'
			// labelSmall={true}
		/>
	);
};
