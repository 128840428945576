import { useMemo, useState, useCallback, useEffect } from 'react';

import {
	DxConnectorPieChart,
	DxFontPieChart,
	DxLabelPieChart,
	DxLegendPieChart,
	DxPieChart,
	DxSeriesPieChart,
	DxTitlePieChart,
} from '../../../components/common/DxCharts';

import { useSelector } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { DiemDanhResponse } from '../../../context/models/mega/DiemDanhResponse';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';
import { createUUID } from '../../../utils/createUUID';

const energySources = [
	{ value: 'chua_diem_danh', name: 'Chưa điểm danh' },
	{ value: 'dung_gio', name: 'Đúng giờ' },
	{ value: 'den_muon', name: 'Đến muộn' },
	{ value: 'vang_co_phep', name: 'Vắng có phép' },
	{ value: 'vang_khong_phep', name: 'Vắng không phép' },
];
interface BaoCaoDiemDanhKhoiPieChartProps {
	ngay_diem_danh: string;
}
const BaoCaoDiemDanhKhoiPieChart = (props: BaoCaoDiemDanhKhoiPieChartProps) => {
	const { ngay_diem_danh } = props;
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<DiemDanhResponse[]>([]);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectDiemDanhAsync(dm_coso_id, dm_truong_id, nam_hoc, ngay_diem_danh);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as DiemDanhResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc, ngay_diem_danh]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dm_coso_id, dm_truong_id, nam_hoc, ngay_diem_danh]);

	const khoiGrouped = useMemo(() => {
		const huyenGrouped = dataReport.reduce((khoiGroupData: DiemDanhResponse[], source) => {
			const exited = khoiGroupData.find((x: DiemDanhResponse) => x.dm_khoi_id === source.dm_khoi_id);
			if (!exited) {
				khoiGroupData.push(source);
			}
			return khoiGroupData;
		}, []);
		return huyenGrouped;
	}, [dataReport]);

	const khoiSoLieuGrouped = useMemo(() => {
		const khoiSoLieuGrouped = khoiGrouped.map((khoi) => {
			let result: any = { ...khoi, chua_diem_danh: 0, dung_gio: 0, den_muon: 0, vang_co_phep: 0, vang_khong_phep: 0 };
			const soLieuKhois = dataReport.filter((map_khoi) => map_khoi.dm_khoi_id === khoi.dm_khoi_id);
			if (soLieuKhois) {
				soLieuKhois.forEach((solieu) => {
					result = {
						...result,
						chua_diem_danh: result.chua_diem_danh + solieu.chua_diem_danh,
						dung_gio: result.dung_gio + solieu.dung_gio,
						den_muon: result.den_muon + solieu.den_muon,
						vang_co_phep: result.vang_co_phep + solieu.vang_co_phep,
						vang_khong_phep: result.vang_khong_phep + solieu.vang_khong_phep,
					};
				});
			}
			return result;
		});
		return khoiSoLieuGrouped.sort((a, b) => (a.ten_khoi > b.ten_khoi ? 1 : -1));
	}, [khoiGrouped, dataReport]);

	return (
		<>
			<div className='row g-gs justify-content-center'>
				{khoiSoLieuGrouped.map((khoi, idx) => {
					const data: any = khoi;
					const soLieuTheoKhois = energySources.map((x) => ({
						name: x.name,
						total: data[x.value] || 0,
					}));
					return (
						<div className='col-md-6 col-xxl-4' key={idx}>
							<div className='card h-100'>
								<div className='card-inner p-0'>
									<DxPieChart
										id={createUUID()}
										palette='Dark Violet'
										dataSource={soLieuTheoKhois}
										type='doughnut'
										// centerRender={(pieChart) => {
										//   return (
										//        <svg>
										//             <circle cx='100' cy='100' r={pieChart.getInnerRadius() - 6} fill='#CBCCFF'></circle>
										//             <text textAnchor='middle' x='100' y='105' style={{ fill: '#494949', fontWeight: 600 }}>
										//                  <tspan x='100'>{khoi.ten_khoi}</tspan>
										//             </text>
										//        </svg>
										//   );
										// }}
										resolveLabelOverlapping={'shift'}
										className='w-100'
										height={240}
									>
										<DxTitlePieChart text={khoi.ten_khoi}>
											<DxFontPieChart size={12} weight={600} />
										</DxTitlePieChart>
										<DxLegendPieChart
											orientation='horizontal'
											itemTextPosition='right'
											horizontalAlignment='center'
											verticalAlignment='bottom'
											// columnCount={5}
										/>
										<DxSeriesPieChart argumentField='name' valueField='total'>
											<DxLabelPieChart visible={true} position='columns'>
												<DxConnectorPieChart visible={true} width={0.5} />
											</DxLabelPieChart>
										</DxSeriesPieChart>
									</DxPieChart>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default BaoCaoDiemDanhKhoiPieChart;
