import { useDispatch, useSelector } from 'react-redux';
import { DxSelectBox } from '../../../base/DxSelectBox';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';
import { useLookupCoSo } from './useLookupCoSo';
import { createUUID } from '../../../../utils/createUUID';
import { useAuth } from '../../../../utils/AuthProvider';
import { useMemo } from 'react';

export const HeaderSelectBoxCoSo = () => {
	const dispatch = useDispatch();
	const { account } = useAuth();
	const { dm_coso_id } = useSelector((state: AppState) => state.common);
	const { dm_coso_all } = useLookupCoSo();

	const dm_coso_ids = useMemo(() => account?.dm_coso_ids || [], [account]);

	const dataSource = useMemo(() => {
		return dm_coso_all.filter(x => dm_coso_ids.includes(x.id)) ?? [];
	}, [dm_coso_all, dm_coso_ids]);

	return (
		<DxSelectBox
			dataSource={dataSource}
			value={dm_coso_id}
			onValueChanged={(value) => {
				dispatch(actions.common.changeCOSO(value || 0));
			}}
			valueExpr='id'
			displayExpr='viet_tat'
			placeholder='Chọn Cơ sở'
			name={`coso_${createUUID()}`}
			// label='Năm làm việc'
			// labelSmall={true}
		/>
	);
};
