import { ApiClient } from "../../base/_ApiClient";

export const baoCaoApi = {
	SelectTongAsync: (dm_coso_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/nam-hoc/${nam_hoc}/tong`);
	},
	SelectDiemDanhAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, ngay_diem_danh: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/ngay/${ngay_diem_danh}/diem-danh`);
	},
	GetBaoCaoKhoiHeLopAsync: (dm_coso_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/nam-hoc/${nam_hoc}/khoi-he-lop`);
	},
	GetBaoCaoLopTheoKhoiAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/lop-theo-khoi`);
	},
	GetBaoCaoNhanSuAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/nhan-su`);
	},
	GetBaoCaoHocSinhTheoKhoiAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/hocsinh-theo-khoi`);
	},
	GetBaoCaoHocSinhTheoHeAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/hocsinh-theo-he`);
	},
	SelectTuyenDuongAsync: (dm_coso_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/nam-hoc/${nam_hoc}/bus-tuyen-duong`);
	},
	SelectBaoCaoDiemDanhAsync: (dm_coso_id: number, nam_hoc: string, bus_tuyenduong_id: number, ngay_diem_danh: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/nam-hoc/${nam_hoc}/bus-tuyenduong/${bus_tuyenduong_id}/ngay-diem-danh/${ngay_diem_danh}/bao-cao-diem-danh`);
	},
	ReportLead: (dm_coso_id: number, tu_van_vien_id: number, from_date: string, to_date: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/tu-van-vien/${tu_van_vien_id}/from-date/${from_date}/to-date/${to_date}/report-lead`);
	},
	SelectTuVanVien: (dm_coso_id: number) => {
		return ApiClient.GET(`/bao-cao/tu-van-vien/${dm_coso_id}`);
	},
};
