import { axiosClient } from './axiosClient';
import jwt_decode from 'jwt-decode';
import mem from 'mem';
import { jwtTokenHelper } from './jwtTokenHelper';
import { BaseResponse } from '../context/base/BaseResponse';
import { RefreshTokenResponse } from '../context/models/my-system/accounts/RefreshTokenResponse';

const baseUrl: string = import.meta.env.VITE_APP_API_URL?.toString() as string;

type jwt_decodeResult = {
	exp: number;
};
let refreshTokenRequest: Promise<any> | null;

const checkIsTokenExpired = (): boolean => {
	try {
		const data: jwt_decodeResult = jwt_decode(jwtTokenHelper.GET());
		if (jwtTokenHelper.GET() && data) {
			const exp = data.exp;
			if (exp > Math.floor(Date.now() / 1000)) {
				return false;
			}
		}
		return true;
	} catch (error) {
		return true;
	}
};

const refreshToken = () => {
	return new Promise<{ isSuccess: boolean; tokens: string }>((resolve) => {
		setTimeout(async () => {
			try {
				const config = {
					method: 'POST',
					url: `${baseUrl}/auth/refresh-token`,
					headers: { 'Content-Type': 'application/json' },
					data: JSON.stringify({ jwt_token: jwtTokenHelper.GET(), refresh_token: jwtTokenHelper.GET('refreshToken') }),
					withCredentials: true,
				};
				const { isSuccess, result } = await axiosClient<any, BaseResponse>(config);
				if (isSuccess && result) {
					const { jwt_token, refresh_token } = result as RefreshTokenResponse;
					resolve({
						isSuccess: true,
						tokens: `${jwt_token} ${refresh_token}`,
					});
				} else {
					resolve({
						isSuccess: false,
						tokens: '',
					});
				}
			} catch (error) {
				resolve({
					isSuccess: false,
					tokens: '',
				});
			}
		}, 1000);
	});
};
const refreshIfTokenExpired = async () => {
	const isTokenExpired = checkIsTokenExpired();
	if (isTokenExpired) {
		refreshTokenRequest = refreshTokenRequest ? refreshTokenRequest : refreshToken();

		const newTokens: { isSuccess: boolean; tokens: string } = await refreshTokenRequest;
		refreshTokenRequest = null;
		if (newTokens.isSuccess && newTokens.tokens.length > 0) {
			jwtTokenHelper.SET(newTokens.tokens);
		} else {
			jwtTokenHelper.CLEAR();
			window.location.href = '/login';
		}
	}
};

const maxAge = 10000;
export const memoizedRefreshToken = mem(refreshIfTokenExpired, { maxAge });
