import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	DxConnectorPieChart,
	DxLabelPieChart,
	DxLegendPieChart,
	DxPieChart,
	DxSeriesPieChart
} from '../../../components/common/DxCharts';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';

interface BaoCaoNhanSuResponse{
	dm_truong_id: number;
	ten_truong: string;
	ten_truong_en: string;
	giaovien_total: number;
	nhanvien_total: number;
	quanly_total: number;
}

const BaoCaoNhanSuPieChart = () => {
	const [dataReport, setDataReport] = useState<BaoCaoNhanSuResponse[]>([]);
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.GetBaoCaoNhanSuAsync(dm_coso_id, dm_truong_id, nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoNhanSuResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	const truongTotal = useMemo(() => {
		return dataReport?.map(x => ({...x, total: x.giaovien_total + x.quanly_total}));
	}, [dataReport]);

	const customizeText = (arg: any) => {
		return `${arg.valueText} (${arg.percentText})`;
	};

	return (
		<>
			<DxPieChart
				id={createUUID()}
				palette='Carmine'
				dataSource={truongTotal}
				resolveLabelOverlapping={'shift'}
				className='w-100'
				height={220}
			>
				{/* <DxTitlePieChart text='Nhân sự'>
					<DxFontPieChart size={18} weight={600}/>
				</DxTitlePieChart> */}
				<DxLegendPieChart
					orientation='horizontal'
					itemTextPosition='right'
					horizontalAlignment='center'
					verticalAlignment='bottom'
					columnCount={4}
				/>
				<DxSeriesPieChart argumentField='ten_truong' valueField='total'>
					<DxLabelPieChart visible={true} position='columns' customizeText={customizeText}>
						<DxConnectorPieChart visible={true} width={0.5} />
					</DxLabelPieChart>
				</DxSeriesPieChart>
			</DxPieChart>
		</>
	);
};

export default BaoCaoNhanSuPieChart;
