import { BaseGetActionStatus, eBaseActionStatus } from '../../../base/eBaseActionStatus';
import { CategoryActionTypes, eCategoryActionTypeIds } from './ICategoryActionTypes';
import { ICategoryState } from './ICategoryState';

const initialState: ICategoryState = {
	status: eBaseActionStatus.idle,
	dm_coso_all: [],
	dm_khoi_all: [],
	dm_namhoc_all: [],
	dm_truong_all: [],
	dm_tu_van_viens: [],
};
const categoryReducer = (state: ICategoryState = initialState, action: CategoryActionTypes): ICategoryState => {
	switch (action.type) {
		case eCategoryActionTypeIds.GET_COSO_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_COSO_SUCCESS:
			return {
				...state,
				dm_coso_all: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_COSO_FAILURE:
			return {
				...state,
				dm_coso_all: [],
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_TRUONG_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_TRUONG_SUCCESS:
			return {
				...state,
				dm_truong_all: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_TRUONG_FAILURE:
			return {
				...state,
				dm_truong_all: [],
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_KHOI_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_KHOI_SUCCESS:
			return {
				...state,
				dm_khoi_all: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_KHOI_FAILURE:
			return {
				...state,
				dm_khoi_all: [],
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_NAMHOC_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_NAMHOC_SUCCESS:
			return {
				...state,
				dm_namhoc_all: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_NAMHOC_FAILURE:
			return {
				...state,
				dm_namhoc_all: [],
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_TUVANVIEN_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_TUVANVIEN_SUCCESS:
			return {
				...state,
				dm_tu_van_viens: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eCategoryActionTypeIds.GET_TUVANVIEN_FAILURE:
			return {
				...state,
				dm_tu_van_viens: [],
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default categoryReducer;
