import { DxConnectorPieChart, DxLabelPieChart, DxLegendPieChart, DxPieChart, DxSeriesPieChart, DxTooltip } from '../../../components/common/DxCharts';
import { createUUID } from '../../../utils/createUUID';

interface BaoCaoChuyenDoiTNProps {
	data: any[];
}

const customizeTooltip = (arg: any) => {
	return {
		text: `${arg.argumentText}: ${arg.valueText}`,
	};
};

const BaoCaoChuyenDoiTN = ({ data }: BaoCaoChuyenDoiTNProps) => {
	return (
		<DxPieChart
			id={createUUID()}
			palette='Dark Violet'
			dataSource={data}
			type='doughnut'
			resolveLabelOverlapping={'shift'}
			className='w-100'
			height={240}
		>
			{/* <DxTitlePieChart text={'Tỉ lệ chuyển đổi theo nguồn'}>
				<DxFontPieChart size={12} weight={600} />
			</DxTitlePieChart> */}
			<DxLegendPieChart
				orientation='horizontal'
				itemTextPosition='right'
				horizontalAlignment='center'
				verticalAlignment='bottom'
				// columnCount={5}
			/>
			<DxSeriesPieChart argumentField='name' valueField='so_luong'>
				<DxLabelPieChart visible={true} position='columns'>
					<DxConnectorPieChart visible={true} width={0.5} />
				</DxLabelPieChart>
			</DxSeriesPieChart>
			<DxTooltip enabled={true} location='edge' customizeTooltip={customizeTooltip} />
		</DxPieChart>
	);
};

export default BaoCaoChuyenDoiTN;
