import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	DxConnectorPieChart,
	DxFontPieChart,
	DxLabelPieChart,
	DxLegendPieChart,
	DxPieChart,
	DxSeriesPieChart,
	DxTitlePieChart,
} from '../../../components/common/DxCharts';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';

interface BaoCaoHocSinhTheoKhoiResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	hocsinh_total: number;
}

const customizeText = (arg: any) => {
	return `${arg.valueText} (${arg.percentText})`;
};
const BaoCaoHocSinhTheoHePieChart = () => {
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhTheoKhoiResponse[]>([]);
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.GetBaoCaoHocSinhTheoHeAsync(dm_coso_id, dm_truong_id, nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhTheoKhoiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	const khoiGrouped = useMemo(() => {
		const huyenGrouped = dataReport.reduce((khoiGroupData: any[], source) => {
			const exited = khoiGroupData.find((x: any) => x.dm_khoi_id === source.dm_khoi_id);
			if (!exited) {
				khoiGroupData.push(source);
			}
			return khoiGroupData;
		}, []);
		return huyenGrouped;
	}, [dataReport]);

	return (
		<>
			<div className='row g-gs justify-content-center'>
				{khoiGrouped.map((khoi, idx) => {
					const soLieuTheoKhois = dataReport.filter((map_he) => map_he.dm_khoi_id === khoi.dm_khoi_id);
					return (
						<div className='col-md-4 col-xxl-3' key={idx}>
							<div className='card h-100'>
								<div className='card-inner'>
									<DxPieChart
										id={createUUID()}
										palette='Dark Violet'
										dataSource={soLieuTheoKhois}
										type='doughnut'
										// centerRender={(pieChart) => {
										// 	return (
										// 		<svg>
										// 			<circle cx='100' cy='100' r={pieChart.getInnerRadius() - 6} fill='#CBCCFF'></circle>
										// 			<text textAnchor='middle' x='100' y='105' style={{ fill: '#494949', fontWeight: 600 }}>
										// 				<tspan x='100'>{khoi.ten_khoi}</tspan>
										// 			</text>
										// 		</svg>
										// 	);
										// }}
										resolveLabelOverlapping={'shift'}
										className='w-100'
										height={180}
										// height={180}
									>
										<DxTitlePieChart text={khoi.ten_khoi}>
											<DxFontPieChart size={12} weight={600} />
										</DxTitlePieChart>
										<DxLegendPieChart
											orientation='horizontal'
											itemTextPosition='right'
											horizontalAlignment='center'
											verticalAlignment='bottom'
											// columnCount={5}
										/>
										<DxSeriesPieChart argumentField='ten_he' valueField='hocsinh_total'>
											<DxLabelPieChart visible={true} position='columns'>
												<DxConnectorPieChart visible={true} width={0.5} />
											</DxLabelPieChart>
										</DxSeriesPieChart>
									</DxPieChart>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default BaoCaoHocSinhTheoHePieChart;
