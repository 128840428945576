import { NotifyHelper } from '../../../../utils/NotifyHelper';
import { BaseGetActionStatus, eBaseActionStatus } from '../../../base/eBaseActionStatus';
import { AccountActionsTypes, eAccountActionTypeIds } from './IAccountActionsTypes';
import { IAccountState } from './IAccountState';

const initialState: IAccountState = {
	accountStatus: eBaseActionStatus.idle,
	loginStatus: eBaseActionStatus.idle,
	accountInfo: null,
	loginResponse: undefined,
};
const accountReducer = (state: IAccountState = initialState, action: AccountActionsTypes): IAccountState => {
	switch (action.type) {
		case eAccountActionTypeIds.LOGIN_REQUEST:
			return {
				...state,
				loginStatus: BaseGetActionStatus(action),
			};
		case eAccountActionTypeIds.LOGIN_SUCCESS:
			NotifyHelper.Success('Đăng nhập thành công');
			return {
				...state,
				loginResponse: action.payload,
				loginStatus: BaseGetActionStatus(action),
			};
		case eAccountActionTypeIds.GET_ACCOUNT_REQUEST:
		case eAccountActionTypeIds.CHANGE_PASSWORD_REQUEST:
			return {
				...state,
				accountStatus: BaseGetActionStatus(action),
			};
		case eAccountActionTypeIds.GET_ACCOUNT_SUCCESS:
			return {
				...state,
				accountInfo: action.payload,
				accountStatus: BaseGetActionStatus(action),
			};
		case eAccountActionTypeIds.LOGIN_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				loginResponse: undefined,
				accountInfo: null,
				loginStatus: BaseGetActionStatus(action),
			};
		case eAccountActionTypeIds.GET_ACCOUNT_FAILURE:
			return {
				...state,
				loginResponse: undefined,
				accountInfo: null,
				accountStatus: BaseGetActionStatus(action),
			};
		case eAccountActionTypeIds.LOGOUT:
			return {
				...state,
				accountStatus: eBaseActionStatus.loading,
			};
		case eAccountActionTypeIds.LOGOUT_SUCCESS:
			return {
				...state,
				loginResponse: undefined,
				accountInfo: null,
				accountStatus: eBaseActionStatus.complete,
			};
		case eAccountActionTypeIds.CHANGE_PASSWORD_SUCCESS:
			NotifyHelper.Success(action.payload.toString());
			return {
				...state,
				accountStatus: BaseGetActionStatus(action),
			};
		case eAccountActionTypeIds.CHANGE_PASSWORD_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				accountStatus: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default accountReducer;
