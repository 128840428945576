import BaoCaoHocTapHocSinhChart from './baocao-theokhoi/BaoCaoHocTapHocSinhChart';
import BaoCaoHocSinhPieChart from './baocao-theokhoi/BaoCaoHocSinhPieChart';
import BaoCaoPhamChatHocSinhChart from './baocao-theokhoi/BaoCaoPhamChatHocSinhChart';
import BaoCaoSucKhoeHocSinhChart from './baocao-theokhoi/BaoCaoSucKhoeHocSinhChart';
import BaoCaoTongBox from './baocao-theokhoi/BaoCaoTongBox';
import BaoCaoTrinhTrangHocSinhChart from './baocao-theokhoi/BaoCaoTrinhTrangHocSinhChart';
import BaoCaoTotNghiepHocSinhChart from './baocao-theokhoi/BaoCaoTotNghiepHocSinhChart';
import BaoCaoHocSinhTheoKhoi from './baocao-theokhoi/BaoCaoHocSinhTheoKhoi';

const BaoCaoHocSinhPage = () => {
	const khois = [
		{
			id: 1,
			name: 'Khối 1',
		},
		{
			id: 1,
			name: 'Khối 2',
		},
		{
			id: 1,
			name: 'Khối 3',
		},
		{
			id: 1,
			name: 'Khối 4',
		},
		{
			id: 1,
			name: 'Khối 5',
		},
	];
	return (
		<>
			<BaoCaoTongBox />
			<div className='nk-block'>
				<div className='row g-gs'>
					<div className='col-md-12 col-xxl-12'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Số lượng học sinh</h6>
								</div>
								<BaoCaoHocSinhTheoKhoi />
							</div>
						</div>
					</div>
					<div className='col-md-4 col-xxl-4'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Tỉ lệ giới tính</h6>
								</div>
								<BaoCaoTrinhTrangHocSinhChart />
							</div>
						</div>
					</div>
					<div className='col-md-8 col-xxl-8'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Tình trạng học sinh</h6>
								</div>
								<BaoCaoTrinhTrangHocSinhChart />
							</div>
						</div>
					</div>
					<div className='col-md-6 col-xxl-6'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Kiểm tra sức khỏe định kỳ</h6>
								</div>
								<BaoCaoSucKhoeHocSinhChart />
							</div>
						</div>
					</div>
					<div className='col-md-6 col-xxl-6'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Phẩm chất đạo đức</h6>
								</div>
								<BaoCaoPhamChatHocSinhChart />
							</div>
						</div>
					</div>
					<div className='col-md-6 col-xxl-6'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Kết quả học tập</h6>
								</div>
								<BaoCaoHocTapHocSinhChart />
							</div>
						</div>
					</div>
					<div className='col-md-6 col-xxl-6'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Kết quả tốt nghiệp</h6>
								</div>
								<BaoCaoTotNghiepHocSinhChart />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BaoCaoHocSinhPage;
