import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App.tsx';
import store from './context/stores/rootStore';

import 'devextreme/dist/css/dx.light.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/dashlite.css';
import './assets/css/dxtable.css';
import './assets/css/theme.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
	// <React.StrictMode>
	<BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>
	// </React.StrictMode>
);
